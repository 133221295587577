// Warning: if updating support.tipping_url please check with client mobile team first
// See also https://taskrabbit.atlassian.net/browse/CAPP-280 as we would love to fix this

const localeConfigObj = {
  "en-US": {
    "support.url": "http://support.taskrabbit.com",
    "support.tipping_url":
      "https://support.taskrabbit.com/hc/articles/216901546",
    "support.cancellation_url":
      "https://support.taskrabbit.com/hc/articles/204411530-How-do-I-reschedule-or-cancel-Will-I-be-charged-",
    "support.happiness_pledge":
      "https://support.taskrabbit.com/hc/articles/360008913792-TaskRabbit-s-Terms-Privacy-Policy-and-Happiness-Pledge",
    "support.donation_dropoff":
      "https://support.taskrabbit.com/hc/en-us/articles/360033107751-TR4G-Accepted-Donations-for-Drop-Off",
    "support.covid19":
      "https://support.taskrabbit.com/hc/en-us/articles/360040752692",
    "localeRoutes.adChoices.url": null,
    "localeRoutes.terms.url": "/terms",
    "localeRoutes.privacy.url": "/privacy",
    "localeRoutes.platformInformation.url": null,
    "localeRoutes.becomeATasker.url": "/become-a-tasker",
    "localeRoutes.impressum.url": null,
    "localeRoutes.covid19.showContactlessTab": false,
    "localeRoutes.covid19.contactless.url": "/services/contactless",
    "footer.extraExplicitPrivacyLink": true,
    "footer.adsOptOutLink": true,
    "ikea.ikea_landing_page_marketplace.mounting.template_id": "2276",
    "ikea.ikea_landing_page_marketplace.moving.template_id": "2247",
    "ikea.ikea_landing_page_marketplace.disassemble.template_id": "2031",
    "ikea.ikea_landing_page_marketplace.handyman.template_id": "2042",
    "ikea.ikea_landing_page_marketplace.minor_repairs.template_id": "2042",
    "ikea.ikea_landing_page_marketplace.lifting.template_id": "2036",
    "ikea.url_paths.landing": "/",
    "ikea.url_paths.quote": "/quote",
    "ikea.url_paths.quote_thanks": "/quote/thank-you",
    "ikea.url_paths.quote_confirm": "/quote/confirm",
    "ikea.url_paths.hire": "/hire",
    "ikea.url_paths.hire_confirm": "/hire/confirm",
    "ikea.url_paths.edit": "/jobs/:job_id/edit",
    "ikea.url_paths.search": "/search",
    "ikea.help.url": "https://help.tr.co",
    "ikea.landing-page.url": "https://www.taskrabbit.com/ikea",
    "tr.blog.url": "https://www.taskrabbit.com/blog/",
    "tr4g.donation.url": "https://www.taskrabbit.com/t/drop-off-donations",
    "tr4g.philanthropic_foundation.url": "https://www.venturesfoundation.org/",
    "tr4g.grants.url": "https://grants.taskrabbit.com/",
    "bat.cities.url": "/locations",
    "bat.categories.url":
      "https://support.taskrabbit.com/hc/articles/204411080",
    "shared.components.ContactlessModalContents.covidResourcesLinks": "<ul><li> •  <a href='https://www.who.int/emergencies/diseases/novel-coronavirus-2019'>WHO Coronavirus Guidance</a></li><li> •  <a href='https://www.cdc.gov/coronavirus/2019-ncov/php/risk-assessment.html'>CDC Coronavirus Guidance</a></li><ul/>",
  },
  "en-CA": {
    "support.url": "http://support.taskrabbit.ca",
    "support.tipping_url":
      "https://support.taskrabbit.ca/hc/articles/216901546",
    "support.cancellation_url":
      "https://support.taskrabbit.ca/hc/articles/204411530-How-do-I-reschedule-or-cancel-Will-I-be-charged-",
    "support.happiness_pledge":
      "https://support.taskrabbit.com/hc/articles/360008913792-TaskRabbit-s-Terms-Privacy-Policy-and-Happiness-Pledge",
    "support.donation_dropoff":
      "https://support.taskrabbit.com/hc/en-us/articles/360033107751-TR4G-Accepted-Donations-for-Drop-Off",
    "support.covid19":
      "https://support.taskrabbit.com/hc/en-ca/articles/360040752692",
    "localeRoutes.adChoices.url": "/adpreferences",
    "localeRoutes.terms.url": "/terms",
    "localeRoutes.privacy.url": "/privacy",
    "localeRoutes.platformInformation.url": null,
    "localeRoutes.becomeATasker.url": "/become-a-tasker",
    "localeRoutes.impressum.url": null,
    "localeRoutes.covid19.showContactlessTab": false,
    "localeRoutes.covid19.contactless.url": "/services/contactless",
    "footer.extraExplicitPrivacyLink": false,
    "footer.adsOptOutLink": false,
    "ikea.ikea_landing_page_marketplace.mounting.template_id": "3413",
    "ikea.ikea_landing_page_marketplace.moving.template_id": "3399",
    "ikea.ikea_landing_page_marketplace.disassemble.template_id": "2159",
    "ikea.ikea_landing_page_marketplace.handyman.template_id": "3350",
    "ikea.ikea_landing_page_marketplace.minor_repairs.template_id": "3350",
    "ikea.ikea_landing_page_marketplace.lifting.template_id": "3344",
    "ikea.url_paths.landing": "/",
    "ikea.url_paths.quote": "/quote",
    "ikea.url_paths.quote_thanks": "/quote/thank-you",
    "ikea.url_paths.quote_confirm": "/quote/confirm",
    "ikea.url_paths.hire": "/hire",
    "ikea.url_paths.hire_confirm": "/hire/confirm",
    "ikea.url_paths.edit": "/jobs/:job_id/edit",
    "ikea.url_paths.search": "/search",
    "ikea.help.url": "https://help.tr.co",
    "ikea.landing-page.url": "https://www.taskrabbit.ca/ikea",
    "tr.blog.url": "https://www.taskrabbit.ca/blog/",
    "tr4g.donation.url": "https://www.taskrabbit.com/t/drop-off-donations",
    "tr4g.philanthropic_foundation.url": "https://www.venturesfoundation.org/",
    "tr4g.grants.url": "https://grants.taskrabbit.com/",
    "bat.cities.url": "/locations",
    "bat.categories.url":
      "https://support.taskrabbit.com/hc/articles/204411080",
    "shared.components.ContactlessModalContents.covidResourcesLinks": "<ul><li> •  <a href=\"https://www.who.int/emergencies/diseases/novel-coronavirus-2019\">WHO Coronavirus Guidance</a></li></ul><ul><li> •  <a href=\"https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19.html\">Government of Canada Guidelines </a></li></ul>",
  },
  "fr-CA": {
    "support.url": "http://support.taskrabbit.ca",
    "support.tipping_url":
      "https://support.taskrabbit.ca/hc/articles/216901546",
    "support.cancellation_url":
      "https://support.taskrabbit.ca/hc/articles/204411530-How-do-I-reschedule-or-cancel-Will-I-be-charged-",
    "support.happiness_pledge":
      "https://support.taskrabbit.com/hc/articles/360008913792-TaskRabbit-s-Terms-Privacy-Policy-and-Happiness-Pledge",
    "support.donation_dropoff":
      "https://support.taskrabbit.com/hc/en-us/articles/360033107751-TR4G-Accepted-Donations-for-Drop-Off",
    "support.covid19":
      "https://support.taskrabbit.com/hc/fr-ca/articles/360040752692",
    "localeRoutes.adChoices.url": "/adpreferences",
    "localeRoutes.terms.url": "/terms",
    "localeRoutes.privacy.url": "/privacy",
    "localeRoutes.platformInformation.url": null,
    "localeRoutes.becomeATasker.url": "/become-a-tasker",
    "localeRoutes.impressum.url": null,
    "localeRoutes.covid19.showContactlessTab": false,
    "localeRoutes.covid19.contactless.url": "/services/sans-contact",
    "footer.extraExplicitPrivacyLink": false,
    "footer.adsOptOutLink": false,
    "ikea.ikea_landing_page_marketplace.mounting.template_id": "5879",
    "ikea.ikea_landing_page_marketplace.moving.template_id": "5865",
    "ikea.ikea_landing_page_marketplace.disassemble.template_id": "5805",
    "ikea.ikea_landing_page_marketplace.handyman.template_id": "5816",
    "ikea.ikea_landing_page_marketplace.minor_repairs.template_id": "5816",
    "ikea.ikea_landing_page_marketplace.lifting.template_id": "5810",
    "ikea.url_paths.landing": "/",
    "ikea.url_paths.quote": "/quote",
    "ikea.url_paths.quote_thanks": "/quote/thank-you",
    "ikea.url_paths.quote_confirm": "/quote/confirm",
    "ikea.url_paths.hire": "/hire",
    "ikea.url_paths.hire_confirm": "/hire/confirm",
    "ikea.url_paths.edit": "/jobs/:job_id/edit",
    "ikea.url_paths.search": "/search",
    "ikea.help.url": "https://help.tr.co",
    "ikea.landing-page.url": "https://www.taskrabbit.ca/ikea",
    "tr.blog.url": "https://www.taskrabbit.ca/blog/",
    "tr4g.donation.url": "https://www.taskrabbit.com/t/drop-off-donations",
    "tr4g.philanthropic_foundation.url": "https://www.venturesfoundation.org/",
    "tr4g.grants.url": "https://grants.taskrabbit.com/",
    "bat.cities.url": "/locations",
    "bat.categories.url":
      "https://support.taskrabbit.com/hc/articles/204411080",
    "shared.components.ContactlessModalContents.covidResourcesLinks": "<ul><li> •  <a href=\"https://www.who.int/emergencies/diseases/novel-coronavirus-2019\">WHO Coronavirus Guidance</a></li></ul><ul><li> •  <a href=\"https://www.canada.ca/en/public-health/services/diseases/coronavirus-disease-covid-19.html\">Government of Canada Guidelines </a></li></ul>",
  },
  "fr-FR": {
    "support.url": "http://support.taskrabbit.fr",
    "support.tipping_url": "https://support.taskrabbit.fr/articles/216901546",
    "support.cancellation_url":
      "https://support.taskrabbit.com/hc/articles/204411530-How-do-I-reschedule-or-cancel-Will-I-be-charged-",
    "support.happiness_pledge":
      "https://support.taskrabbit.com/hc/articles/360008913792-TaskRabbit-s-Terms-Privacy-Policy-and-Happiness-Pledge",
    "support.donation_dropoff":
      "https://support.taskrabbit.com/hc/en-us/articles/360033107751-TR4G-Accepted-Donations-for-Drop-Off",
    "support.covid19":
      "https://support.taskrabbit.com/hc/fr-fr/articles/360040752692",
    "localeRoutes.adChoices.url": null,
    "localeRoutes.terms.url": "/conditions-generales",
    "localeRoutes.privacy.url": "/informations-personelles",
    "localeRoutes.platformInformation.url": "/disclosure",
    "localeRoutes.becomeATasker.url": "/devenir-taskeur",
    "localeRoutes.impressum.url": null,
    "localeRoutes.covid19.showContactlessTab": false,
    "localeRoutes.covid19.contactless.url": "/services/sans-contact",
    "footer.extraExplicitPrivacyLink": false,
    "footer.adsOptOutLink": false,
    "ikea.ikea_landing_page_marketplace.mounting.template_id": "3752",
    "ikea.ikea_landing_page_marketplace.moving.template_id": "3813",
    "ikea.ikea_landing_page_marketplace.disassemble.template_id": "3739",
    "ikea.ikea_landing_page_marketplace.handyman.template_id": "3750",
    "ikea.ikea_landing_page_marketplace.minor_repairs.template_id": "3750",
    "ikea.ikea_landing_page_marketplace.lifting.template_id": "3744",
    "ikea.url_paths.landing": "/",
    "ikea.url_paths.quote": "/devis",
    "ikea.url_paths.quote_thanks": "/devis/merci",
    "ikea.url_paths.quote_confirm": "/devis/confirmation",
    "ikea.url_paths.hire": "/articles",
    "ikea.url_paths.hire_confirm": "/articles/confirmation",
    "ikea.url_paths.edit": "/jobs/:job_id/edit",
    "ikea.url_paths.search": "/search",
    "ikea.help.url": "https://help.tr.co",
    "ikea.landing-page.url": "https://www.taskrabbit.fr/ikea",
    "tr.blog.url": "https://www.taskrabbit.fr/blog/",
    "tr4g.donation.url": "https://www.taskrabbit.com/t/drop-off-donations",
    "tr4g.philanthropic_foundation.url": "https://www.venturesfoundation.org/",
    "tr4g.grants.url": "https://grants.taskrabbit.com/",
    "bat.cities.url": "/locations",
    "bat.categories.url":
      "https://support.taskrabbit.com/hc/articles/204411080",
    "shared.components.ContactlessModalContents.covidResourcesLinks": "<ul><li> •  <a href=\"https://www.who.int/fr/emergencies/diseases/novel-coronavirus-2019\">Directives de l'OMS concernant le coronavirus</a></li><li> •  <a href=\"https://solidarites-sante.gouv.fr/actualites/presse/communiques-de-presse/article/communique-covid-19-rester-chez-soi-pour-freiner-la-propagation-de-l-epidemie\">Minist&egrave;re des Solidarit&eacute;s et de la Sant&eacute;et</a></li></ul>",
  },
  "en-GB": {
    "support.url": "http://support.taskrabbit.co.uk",
    "support.tipping_url":
      "https://support.taskrabbit.co.uk/hc/articles/216901546",
    "support.cancellation_url":
      "https://support.taskrabbit.co.uk/hc/articles/204411530-How-do-I-reschedule-or-cancel-Will-I-be-charged-",
    "support.happiness_pledge":
      "https://support.taskrabbit.com/hc/articles/360008913792-TaskRabbit-s-Terms-Privacy-Policy-and-Happiness-Pledge",
    "support.donation_dropoff":
      "https://support.taskrabbit.com/hc/en-us/articles/360033107751-TR4G-Accepted-Donations-for-Drop-Off",
    "support.covid19":
      "https://support.taskrabbit.com/hc/en-gb/articles/360040752692",
    "localeRoutes.adChoices.url": null,
    "localeRoutes.terms.url": "/terms",
    "localeRoutes.privacy.url": "/privacy",
    "localeRoutes.platformInformation.url": null,
    "localeRoutes.becomeATasker.url": "/become-a-tasker",
    "localeRoutes.impressum.url": null,
    "localeRoutes.covid19.showContactlessTab": false,
    "localeRoutes.covid19.contactless.url": "/services/contactless",
    "footer.extraExplicitPrivacyLink": false,
    "footer.adsOptOutLink": false,
    "ikea.ikea_landing_page_marketplace.mounting.template_id": "2319",
    "ikea.ikea_landing_page_marketplace.moving.template_id": "2310",
    "ikea.ikea_landing_page_marketplace.disassemble.template_id": "2159",
    "ikea.ikea_landing_page_marketplace.handyman.template_id": "2170",
    "ikea.ikea_landing_page_marketplace.minor_repairs.template_id": "2170",
    "ikea.ikea_landing_page_marketplace.lifting.template_id": "2164",
    "ikea.url_paths.landing": "/",
    "ikea.url_paths.quote": "/quote",
    "ikea.url_paths.quote_thanks": "/quote/thank-you",
    "ikea.url_paths.quote_confirm": "/quote/confirm",
    "ikea.url_paths.hire": "/hire",
    "ikea.url_paths.hire_confirm": "/hire/confirm",
    "ikea.url_paths.edit": "/jobs/:job_id/edit",
    "ikea.url_paths.search": "/search",
    "ikea.help.url": "https://help.tr.co",
    "ikea.landing-page.url": "https://www.taskrabbit.co.uk/ikea",
    "tr.blog.url": "https://www.taskrabbit.co.uk/blog/",
    "tr4g.donation.url": "https://www.taskrabbit.com/t/drop-off-donations",
    "tr4g.philanthropic_foundation.url": "https://www.venturesfoundation.org/",
    "tr4g.grants.url": "https://grants.taskrabbit.com/",
    "bat.cities.url": "/locations",
    "bat.categories.url":
      "https://support.taskrabbit.com/hc/articles/204411080",
    "shared.components.ContactlessModalContents.covidResourcesLinks": "<ul> <li> •  <strong> <a href=\"https://www.who.int/emergencies/diseases/novel-coronavirus-2019\">WHO Coronavirus Guidance</a>&nbsp;</strong></li> </ul> <ul> <li> •  <a href=\"https://www.nhs.uk/conditions/coronavirus-covid-19/\">NHS Coronavirus Guidelines</a></li> <li> •  <a href=\"https://www.gov.uk/government/publications/covid-19-stay-at-home-guidance\">UK Government Guidance</a> for households with possible coronavirus infection</li> <li> •  <a href=\"https://www.gov.uk/guidance/working-safely-during-coronavirus-covid-19/homes\">UK Government Guidance</a> for safely working in the home</li> </ul>",
  },
  "de-DE": {
    "support.url": "http://support.taskrabbit.de",
    "support.tipping_url":
      "https://support.taskrabbit.com/hc/articles/216901546",
    "support.cancellation_url":
      "https://support.taskrabbit.com/hc/articles/204411530-How-do-I-reschedule-or-cancel-Will-I-be-charged-",
    "support.happiness_pledge":
      "https://support.taskrabbit.com/hc/articles/360008913792-TaskRabbit-s-Terms-Privacy-Policy-and-Happiness-Pledge",
    "support.donation_dropoff":
      "https://support.taskrabbit.com/hc/en-us/articles/360033107751-TR4G-Accepted-Donations-for-Drop-Off",
    "support.covid19":
      "https://support.taskrabbit.com/hc/de/articles/360040752692",
    "localeRoutes.adChoices.url": null,
    "localeRoutes.terms.url": "/geschaeftsbedingungen",
    "localeRoutes.privacy.url": "/datenschutz",
    "localeRoutes.platformInformation.url": null,
    "localeRoutes.becomeATasker.url": "/werde-tasker",
    "localeRoutes.impressum.url": "/impressum",
    "localeRoutes.covid19.showContactlessTab": false,
    "localeRoutes.covid19.contactless.url": "/m/kontaktlose",
    "footer.extraExplicitPrivacyLink": false,
    "footer.adsOptOutLink": false,
    "ikea.ikea_landing_page_marketplace.mounting.template_id": "4826",
    "ikea.ikea_landing_page_marketplace.moving.template_id": "4812",
    "ikea.ikea_landing_page_marketplace.disassemble.template_id": "4752",
    "ikea.ikea_landing_page_marketplace.handyman.template_id": "4763",
    "ikea.ikea_landing_page_marketplace.minor_repairs.template_id": "4763",
    "ikea.ikea_landing_page_marketplace.lifting.template_id": "4757",
    "ikea.url_paths.landing": "/",
    "ikea.url_paths.quote": "/quote",
    "ikea.url_paths.quote_thanks": "/quote/thank-you",
    "ikea.url_paths.quote_confirm": "/quote/confirm",
    "ikea.url_paths.hire": "/hire",
    "ikea.url_paths.hire_confirm": "/hire/confirm",
    "ikea.url_paths.edit": "/jobs/:job_id/edit",
    "ikea.url_paths.search": "/search",
    "ikea.help.url": "https://help.tr.co",
    "ikea.landing-page.url": "https://www.taskrabbit.de/ikea",
    "tr.blog.url": "https://www.taskrabbit.de/blog/",
    "tr4g.donation.url": "https://www.taskrabbit.com/t/drop-off-donations",
    "tr4g.philanthropic_foundation.url": "https://www.venturesfoundation.org/",
    "tr4g.grants.url": "https://grants.taskrabbit.com/",
    "bat.cities.url": "/locations",
    "bat.categories.url":
      "https://support.taskrabbit.com/hc/articles/204411080",
    "shared.components.ContactlessModalContents.covidResourcesLinks": "<ul><li> •  <a href=\"https://www.who.int/emergencies/diseases/novel-coronavirus-2019\">Coronavirus-Informationen der WHO</a></li><li> •  Europ&auml;isches Zentrum f&uuml;r die Pr&auml;vention und die Kontrolle von Krankheiten (ECDC) <a href=\"https://www.zusammengegencorona.de/informieren/praevention/\">auf Deutsch</a> und <a href=\"https://www.ecdc.europa.eu/en\">auf Englisch</a>.</li></ul>",
  },
  "es-ES": {
    "support.url": "http://ayuda.taskrabbit.es",
    "support.tipping_url":
      "https://support.taskrabbit.com/hc/articles/216901546",
    "support.cancellation_url":
      "https://support.taskrabbit.com/hc/articles/204411530-How-do-I-reschedule-or-cancel-Will-I-be-charged-",
    "support.happiness_pledge":
      "https://support.taskrabbit.com/hc/es-es/articles/360008913792-TaskRabbit-s-Terms-Privacy-Policy-and-Happiness-Pledge",
    "support.donation_dropoff":
      "https://support.taskrabbit.com/hc/en-us/articles/360033107751-TR4G-Accepted-Donations-for-Drop-Off",
    "support.covid19":
      "https://support.taskrabbit.com/hc/es-es/articles/360040752692",
    "localeRoutes.adChoices.url": null,
    "localeRoutes.terms.url": "/condiciones",
    "localeRoutes.privacy.url": "/privacidad",
    "localeRoutes.platformInformation.url": null,
    "localeRoutes.becomeATasker.url": "/hazte-tasker",
    "localeRoutes.impressum.url": null,
    "localeRoutes.covid19.showContactlessTab": false,
    "localeRoutes.covid19.contactless.url": "/m/sin-contacto/",
    "footer.extraExplicitPrivacyLink": false,
    "footer.adsOptOutLink": false,
    "ikea.ikea_landing_page_marketplace.mounting.template_id": "5557",
    "ikea.ikea_landing_page_marketplace.moving.template_id": "5604",
    "ikea.ikea_landing_page_marketplace.disassemble.template_id": "5544",
    "ikea.ikea_landing_page_marketplace.handyman.template_id": "5555",
    "ikea.ikea_landing_page_marketplace.minor_repairs.template_id": "5555",
    "ikea.ikea_landing_page_marketplace.lifting.template_id": "5549",
    "ikea.url_paths.landing": "/",
    "ikea.url_paths.quote": "/quote",
    "ikea.url_paths.quote_thanks": "/quote/thank-you",
    "ikea.url_paths.quote_confirm": "/quote/confirm",
    "ikea.url_paths.hire": "/hire",
    "ikea.url_paths.hire_confirm": "/hire/confirm",
    "ikea.url_paths.edit": "/jobs/:job_id/edit",
    "ikea.url_paths.search": "/search",
    "ikea.help.url": "https://ayuda.tr.co",
    "ikea.landing-page.url": "https://www.taskrabbit.es/ikea",
    "tr.blog.url": "https://www.taskrabbit.es/blog/",
    "tr4g.donation.url": "https://www.taskrabbit.com/t/drop-off-donations",
    "tr4g.philanthropic_foundation.url": "https://www.venturesfoundation.org/",
    "tr4g.grants.url": "https://grants.taskrabbit.com/",
    "bat.cities.url": "/locations",
    "bat.categories.url":
      "https://support.taskrabbit.com/hc/articles/204411080",
    "shared.components.ContactlessModalContents.covidResourcesLinks": "<ul> <li> •  <a href=\"https://www.who.int/es/emergencies/diseases/novel-coronavirus-2019\">Directrices sobre el coronavirus de la OMS</a></li> <li> •  <a href=\"https://www.mscbs.gob.es/profesionales/saludPublica/ccayes/alertasActual/nCov-China/ciudadania.htm\">Ministerio de Sanidad</a></li> </ul> <h5><strong>Para los taskers: acepta &uacute;nicamente las tareas que cumplan con la normativa vigente en tu zona. Para llevar a cabo tus tareas sin contacto, te recomendamos completar y llevar siempre en tu m&oacute;vil el &laquo;Certificado autoresponsable de desplazamiento en el marco del estado de alarma por la crisis sanitaria por la COVID-19&raquo; de la Generalitat, que puedes obtener </strong> <a href=\"https://confinapp.gencat.cat/#/\"><strong>aqu&iacute;</strong></a><strong>. Tendr&aacute;s que generar un certificado nuevo para cada ocasi&oacute;n.</strong></h5>",

  },
  "ca-ES": {
    "support.url": "http://ajuda.taskrabbit.es",
    "support.tipping_url":
      "https://support.taskrabbit.com/hc/articles/216901546",
    "support.cancellation_url":
      "https://support.taskrabbit.com/hc/articles/204411530-How-do-I-reschedule-or-cancel-Will-I-be-charged-",
    "support.happiness_pledge":
      "https://support.taskrabbit.com/hc/es-es/articles/360008913792-TaskRabbit-s-Terms-Privacy-Policy-and-Happiness-Pledge",
    "support.donation_dropoff":
      "https://support.taskrabbit.com/hc/en-us/articles/360033107751-TR4G-Accepted-Donations-for-Drop-Off",
    "support.covid19":
      "https://support.taskrabbit.com/hc/ca/articles/360040752692",
    "localeRoutes.adChoices.url": null,
    "localeRoutes.terms.url": "/condicions",
    "localeRoutes.privacy.url": "/privadesa",
    "localeRoutes.platformInformation.url": null,
    "localeRoutes.becomeATasker.url": "/converteix-te-en-tasker",
    "localeRoutes.impressum.url": null,
    "localeRoutes.covid19.showContactlessTab": false,
    "localeRoutes.covid19.contactless.url": "/m/sense-contacte/",
    "footer.extraExplicitPrivacyLink": false,
    "footer.adsOptOutLink": false,
    "ikea.ikea_landing_page_marketplace.mounting.template_id": "6045",
    "ikea.ikea_landing_page_marketplace.moving.template_id": "6092",
    "ikea.ikea_landing_page_marketplace.disassemble.template_id": "6032",
    "ikea.ikea_landing_page_marketplace.handyman.template_id": "6043",
    "ikea.ikea_landing_page_marketplace.minor_repairs.template_id": "6043",
    "ikea.ikea_landing_page_marketplace.lifting.template_id": "6037",
    "ikea.url_paths.landing": "/",
    "ikea.url_paths.quote": "/quote",
    "ikea.url_paths.quote_thanks": "/quote/thank-you",
    "ikea.url_paths.quote_confirm": "/quote/confirm",
    "ikea.url_paths.hire": "/hire",
    "ikea.url_paths.hire_confirm": "/hire/confirm",
    "ikea.url_paths.edit": "/jobs/:job_id/edit",
    "ikea.url_paths.search": "/search",
    "ikea.help.url": "https://ajuda.tr.co",
    "ikea.landing-page.url": "https://www.taskrabbit.es/ikea",
    "tr.blog.url": "https://www.taskrabbit.es/blog/",
    "tr4g.donation.url": "https://www.taskrabbit.com/t/drop-off-donations",
    "tr4g.philanthropic_foundation.url": "https://www.venturesfoundation.org/",
    "tr4g.grants.url": "https://grants.taskrabbit.com/",
    "bat.cities.url": "/locations",
    "bat.categories.url":
      "https://support.taskrabbit.com/hc/articles/204411080",
    "shared.components.ContactlessModalContents.covidResourcesLinks": "<ul><li> •  <a href='https://www.who.int/emergencies/diseases/novel-coronavirus-2019'>Recomanacions de l'OMS sobre el coronavirus</a></li><li> •  <a href='https://www.cdc.gov/coronavirus/2019-ncov/php/risk-assessment.html'>Normes del Centre per al Control i la Prevenció de Malalties</a></li><ul/>",
  },
  "pt-PT": {
    "support.url": "http://assistencia.taskrabbit.pt",
    "support.tipping_url":
      "https://support.taskrabbit.com/hc/articles/216901546",
    "support.cancellation_url":
      "https://support.taskrabbit.com/hc/articles/204411530-How-do-I-reschedule-or-cancel-Will-I-be-charged-",
    "support.happiness_pledge":
      "https://support.taskrabbit.com/hc/articles/360008913792-TaskRabbit-s-Terms-Privacy-Policy-and-Happiness-Pledge",
    "support.donation_dropoff":
      "https://support.taskrabbit.com/hc/articles/360033107751-TR4G-Accepted-Donations-for-Drop-Off",
    "support.covid19":
      "https://support.taskrabbit.com/hc/pt/articles/360040752692",
    "localeRoutes.adChoices.url": null,
    "localeRoutes.terms.url": "/termos",
    "localeRoutes.privacy.url": "/privacidade",
    "localeRoutes.platformInformation.url": null,
    "localeRoutes.becomeATasker.url": "/seja-um-tasker",
    "localeRoutes.impressum.url": null,
    "localeRoutes.covid19.showContactlessTab": false,
    "localeRoutes.covid19.contactless.url": "/servicos/contactless/",
    "footer.extraExplicitPrivacyLink": false,
    "footer.adsOptOutLink": false,
    "ikea.ikea_landing_page_marketplace.mounting.template_id": "6427",
    "ikea.ikea_landing_page_marketplace.moving.template_id": "6440",
    "ikea.ikea_landing_page_marketplace.disassemble.template_id": "6421",
    "ikea.ikea_landing_page_marketplace.handyman.template_id": "6425",
    "ikea.ikea_landing_page_marketplace.minor_repairs.template_id": "6425",
    "ikea.ikea_landing_page_marketplace.lifting.template_id": "6453",
    "ikea.url_paths.landing": "/",
    "ikea.url_paths.quote": "/quote",
    "ikea.url_paths.quote_thanks": "/quote/thank-you",
    "ikea.url_paths.quote_confirm": "/quote/confirm",
    "ikea.url_paths.hire": "/hire",
    "ikea.url_paths.hire_confirm": "/hire/confirm",
    "ikea.url_paths.edit": "/jobs/:job_id/edit",
    "ikea.url_paths.search": "/search",
    "ikea.help.url": "https://assistencia.tr.co",
    "ikea.landing-page.url": "https://www.taskrabbit.pt/ikea",
    "tr.blog.url": "https://www.taskrabbit.pt/blog/",
    "tr4g.donation.url": "https://www.taskrabbit.com/t/drop-off-donations",
    "tr4g.philanthropic_foundation.url": "https://www.venturesfoundation.org/",
    "tr4g.grants.url": "https://grants.taskrabbit.com/",
    "bat.cities.url": "/locations",
    "bat.categories.url":
      "https://support.taskrabbit.com/hc/articles/204411080",
    "shared.components.ContactlessModalContents.covidResourcesLinks": "<ul><li> •  <a href='https://www.who.int/emergencies/diseases/novel-coronavirus-2019'>Diretrizes da Organização Mundial de Saúde em relação ao coronavírus</a></li><li> •  <a href='https://www.cdc.gov/coronavirus/2019-ncov/php/risk-assessment.html'>Diretrizes dos Centros de Controle e Prevenção de Doenças em relação ao coronavírus</a></li><ul/>",
  },
  "it-IT": {
    "support.url": "http://assistenza.taskrabbit.it",
    "support.tipping_url":
      "https://support.taskrabbit.com/hc/articles/216901546",
    "support.cancellation_url":
      "https://support.taskrabbit.com/hc/articles/204411530-How-do-I-reschedule-or-cancel-Will-I-be-charged-",
    "support.happiness_pledge":
      "https://support.taskrabbit.com/hc/articles/360008913792-TaskRabbit-s-Terms-Privacy-Policy-and-Happiness-Pledge",
    "support.donation_dropoff":
      "https://support.taskrabbit.com/hc/articles/360033107751-TR4G-Accepted-Donations-for-Drop-Off",
    "support.covid19":
      "https://support.taskrabbit.com/hc/it/articles/360040752692",
    "localeRoutes.adChoices.url": null,
    "localeRoutes.terms.url": "/termini-di-servizio",
    "localeRoutes.privacy.url": "/privacy",
    "localeRoutes.platformInformation.url": null,
    "localeRoutes.becomeATasker.url": "/diventa-un-tasker",
    "localeRoutes.impressum.url": null,
    "localeRoutes.covid19.showContactlessTab": false,
    "localeRoutes.covid19.contactless.url": "/servizi/contactless",
    "footer.extraExplicitPrivacyLink": false,
    "footer.adsOptOutLink": false,
    "ikea.ikea_landing_page_marketplace.mounting.template_id": "6427",
    "ikea.ikea_landing_page_marketplace.moving.template_id": "6440",
    "ikea.ikea_landing_page_marketplace.disassemble.template_id": "6421",
    "ikea.ikea_landing_page_marketplace.handyman.template_id": "6425",
    "ikea.ikea_landing_page_marketplace.minor_repairs.template_id": "6425",
    "ikea.ikea_landing_page_marketplace.lifting.template_id": "6453",
    "ikea.url_paths.landing": "/",
    "ikea.url_paths.quote": "/quote",
    "ikea.url_paths.quote_thanks": "/quote/thank-you",
    "ikea.url_paths.quote_confirm": "/quote/confirm",
    "ikea.url_paths.hire": "/hire",
    "ikea.url_paths.hire_confirm": "/hire/confirm",
    "ikea.url_paths.edit": "/jobs/:job_id/edit",
    "ikea.url_paths.search": "/search",
    "ikea.help.url": "https://assistenza.tr.co",
    "ikea.landing-page.url": "https://www.taskrabbit.it/ikea",
    "tr.blog.url": "https://www.taskrabbit.it/blog/",
    "tr4g.donation.url": "https://www.taskrabbit.com/t/drop-off-donations",
    "tr4g.philanthropic_foundation.url": "https://www.venturesfoundation.org/",
    "tr4g.grants.url": "https://grants.taskrabbit.com/",
    "bat.cities.url": "/locations",
    "bat.categories.url":
      "https://support.taskrabbit.com/hc/articles/204411080",
    "shared.components.ContactlessModalContents.covidResourcesLinks": "<ul><li> • <a href='https://www.who.int/emergencies/diseases/novel-coronavirus-2019'>Linee guida sul coronavirus dell'OMS</a></li><li> • <a href='https://www.cdc.gov/coronavirus/2019-ncov/php/risk-assessment.html'>Linee guida sul coronavirus del CDC</a></li><ul/>",
  },
};
localeConfigObj["en-FR"] = localeConfigObj["fr-FR"];
localeConfigObj["en-ES"] = localeConfigObj["es-ES"];
localeConfigObj["en-DE"] = localeConfigObj["de-DE"];
localeConfigObj["en-PT"] = localeConfigObj["pt-PT"];
localeConfigObj["en-IT"] = localeConfigObj["it-IT"];
export { localeConfigObj };

export const localeConfig = (locale, key, config = null) => {
  config = config || localeConfigObj; // eslint-disable-line

  if (locale === "en") locale = "en-US"; // eslint-disable-line

  if (key) {
    return config[locale][key];
  }

  return config[locale];
};
