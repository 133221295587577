import React from "react";
import { defineMessages } from "react-intl";
import { localeConfig } from "../../../config/localeDriven";
import { BlockFooterLink, FooterLink } from "./FooterLink";
import { FooterReferralLink } from "./CompanyLinks/ReferralLink";

const messages = defineMessages({
  aboutUs: {
    id: "components.Footer.StyledFooter.CompanyLinks.aboutus",
    defaultMessage: "About Us",
  },
  careers: {
    id: "components.Footer.StyledFooter.CompanyLinks.careers",
    defaultMessage: "Careers",
  },
  press: {
    id: "components.Footer.StyledFooter.CompanyLinks.press",
    defaultMessage: "Press",
  },
  forGood: {
    id: "components.Footer.StyledFooter.CompanyLinks.forGood",
    defaultMessage: "Taskrabbit for Good",
  },
  blog: {
    id: "components.Footer.StyledFooter.CompanyLinks.blog",
    defaultMessage: "Blog",
  },
  terms: {
    id: "components.Footer.StyledFooter.CompanyLinks.terms",
    defaultMessage: "Terms",
  },
  privacy: {
    id: "components.Footer.StyledFooter.CompanyLinks.privacy",
    defaultMessage: "Privacy",
  },
  californiaConsumer: {
    id: "components.Footer.StyledFooter.CompanyLinks.californiaConsumer",
    defaultMessage: "California Consumer Notice",
  },
  adsOptOut: {
    id: "components.Footer.StyledFooter.CompanyLinks.adsOptOut",
    defaultMessage: "Do Not Sell My Personal Information",
  },
  adChoices: {
    id: "components.Footer.StyledFooter.CompanyLinks.adchoices",
    defaultMessage: "About Our Ads",
  },
  platformInfo: {
    id: "components.Footer.StyledFooter.CompanyLinks.platformInfo",
    defaultMessage: "Platform Information",
  },
  impressum: {
    id: "components.Footer.StyledFooter.CompanyLinks.impressum",
    defaultMessage: "Impressum",
  },
});

export function CompanyLinks({ locale, loggedIn, referralData }) {
  const adChoicesPath = localeConfig(locale, "localeRoutes.adChoices.url");
  const displayAdsOptOutLink = localeConfig(locale, "footer.adsOptOutLink");
  const displayCaliforniaConsumerNoticeLink = localeConfig(
    locale,
    "footer.extraExplicitPrivacyLink"
  );
  const impressumPath = localeConfig(locale, "localeRoutes.impressum.url");
  const platformInfoPath = localeConfig(
    locale,
    "localeRoutes.platformInformation.url"
  );
  const privacyPath = localeConfig(locale, "localeRoutes.privacy.url");
  const termsPath = localeConfig(locale, "localeRoutes.terms.url");

  // If the blog path for that region doesn't exist yet, default to just the NA blog
  const blogPath = localeConfig(locale, "tr.blog.url")
    ? localeConfig(locale, "tr.blog.url")
    : "https://www.taskrabbit.com/blog";

  return (
    <>
      <FooterReferralLink loggedIn={loggedIn} referralData={referralData} />
      <BlockFooterLink path="/about" message={messages.aboutUs} />
      <BlockFooterLink path="/careers" message={messages.careers} />
      {(locale == "en-US" || locale == "en-GB") && (
        <BlockFooterLink href="https://www.taskrabbit.com/blog/partner-lp">
          Partner with Taskrabbit
        </BlockFooterLink>
      )}
      <BlockFooterLink path="/press" message={messages.press} />
      <BlockFooterLink path="/taskrabbitforgood" message={messages.forGood} />
      <BlockFooterLink
        href={blogPath}
        message={messages.blog}
        target="_blank"
        rel="noopener"
      />

      <div>
        <FooterLink path={termsPath} message={messages.terms} /> &amp;{" "}
        <FooterLink path={privacyPath} message={messages.privacy} />
      </div>
      {displayCaliforniaConsumerNoticeLink ? (
        <BlockFooterLink
          path={privacyPath}
          message={messages.californiaConsumer}
        />
      ) : null}
      {displayAdsOptOutLink ? (
        <BlockFooterLink
          href="https://optout.privacyrights.info"
          message={messages.adsOptOut}
          target="_blank"
          rel="noopener"
        />
      ) : null}
      {adChoicesPath ? (
        <BlockFooterLink path={adChoicesPath} message={messages.adChoices} />
      ) : null}
      {platformInfoPath ? (
        <BlockFooterLink
          path={platformInfoPath}
          message={messages.platformInfo}
        />
      ) : null}
      {impressumPath ? (
        <BlockFooterLink path={impressumPath} message={messages.impressum} />
      ) : null}
    </>
  );
}
